//=====================================
// #PORTFOLIO
//=====================================
.portfolio__item {
  margin-bottom: gutter(1);
}

@include breakpoint(small) {
  .portfolio__item {
    margin-bottom: gutter(2);
  }
}
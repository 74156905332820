// ==========================================================================
// ANIMATIONS
// ==========================================================================

.cta, .menu__item {
  display: inline-block;
  position: relative;
  transition: color 0.3s ease 0s;

  &--light:after {
    background: palette(brand, reverse);
  }

  &--dark:after {
    background: palette(brand, primary);
  }

  &--blue:after {
    background: palette(brand, tertiary);
  }


}

.cta:after, .menu__item:after {
  //background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  margin-top: 5px;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.cta--active:after {
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  margin-top: 5px;
  width: 100%;
}


.menu__item:after {
  height: 2px;
  margin-top: 0;
}

.menu__item:hover {
  color: palette(brand, tertiary);

}

.menu__item--dark:hover {
  color: palette(brand, primary);
}


.cta:hover:after, .menu__item:hover:after {
  width: 100%;
  left: 0;


}

.image--zoom {
  transition: all 5s ease;

  &:hover {
    transform: scale(1.1);
  }
}




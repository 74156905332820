.about__title p {

  font-family: $big-heading-font;
  line-height: 1.2;
  font-size: 4.5rem;

  @include breakpoint(small) {
    font-size: 7.5rem;
  }
}

.about__hero {
  display: flex;
  flex-direction: column-reverse;
}


.about__intro p, .about__intro {
  line-height: 1.75;
  font-size: 2.6rem;
  font-weight: bold;

  @include breakpoint-max(medium) {
    font-size: 2rem;
  }


  &--30 {
    font-size: 3rem;
  }
}

.quoteblock__body p {
  font-weight: bold;
  font-size: 2.6rem;

  @include breakpoint-max(medium) {
    font-size: 2rem;
  }
}

.icon__diamond {
  height: 32px;
  width: 32px;
  border: 2px solid #C7A57A;
  transform: rotate(45deg);
  transition: all .3s ease-in-out;

  &:hover {
    transform: rotate(45deg) scale(1.25);
  }

  &--margin {
    margin-right: 40px;
  }

  & img {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
  }
}
// ==========================================================================
// HTML & Body
// ==========================================================================
$base-line-height: 28;
$min-font: 17px;
$max-font: 21px;

html {
  //height: 100%;
}

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: $body-font;
  font-style: normal;
  font-weight: 400;
  color: palette(grey, dark);
  background: white;

  @include breakpoint(large) {
    font-size: 62.5% !important;
    /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */
  }
}

body {
  position: relative;
  background: #F6F6F8 url('/dist/img/watercolour-swish-1.svg');
  background-size: auto;
  background-position: top right;
  background-repeat: no-repeat;

  &.fix {

    @include breakpoint-max(medium) {
      overflow: hidden;
    }
  }
}

//::-moz-selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}
//
//::selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}

main {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @extend %clearfix;
}

.rel {
  position: relative;
}

.highlight {
  width: 40px;
  height: 3px;
  background-color: white;
}

.nav--active {
  color: palette(brand, tertiary);

  &::after {
    bottom: 0;
    background: palette(brand, tertiary);
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    margin-top: 1px;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 100%;
  }

  &:hover {
    color: palette(brand, tertiary);
  }


}


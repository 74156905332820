//=====================================
// #HEADINGS
//=====================================
h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: $heading-font;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 25px;
}

h1, .h1 {
  font-size: 6rem;
  @include breakpoint(small) { font-size: $h1-size; }
}

h2, .h2 {
  font-size: 4rem;
  @include breakpoint(small) { font-size: $h2-size; }
}

h3, .h3 { font-size: $h3-size; }

h4, .h4 { font-size: $h4-size; }

.heading--lg {
  font-family: $big-heading-font;
  font-size: $h1-size;
}

.heading--hero, .heading--hero p {
  font-family: $big-heading-font;
  font-size: 7.5rem;
  margin: 0;

  @include breakpoint(x-small) {
    font-size: 5rem;
  }
}

.heading--md {
  font-family: $big-heading-font;
  font-size: $h2-size;
}

//=====================================
// #TEXT
//=====================================
p, .p {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 300;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

.nav > p,
.nav > .p,
.nav {
  font-size: $p-size--nav;
}



a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: palette(black);
  font-weight: 400;
  transition: color .3s;

  &:hover {

  }
}

.link--icon {
  span {
    margin-left: 10px;
  }
}

//=====================================
// #LISTS
//=====================================
ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;
    li { padding: 0; }
  }

  &.ul--row {
    li { display: inline-block; }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  color: palette(black);
  padding-bottom: 15px;
}

strong { font-weight: bold; }
em { font-style: italic; }

//=====================================
// #COLOURS/LINKS
//=====================================
.color--white {
  color: white;
  > * {
    color: white;
  }
}

.color--brand-primary {
  color: palette(brand, primary);
}




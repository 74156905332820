// ==========================================================================
// #FOOTER
// ==========================================================================
#footer {
  position: relative;
  z-index: 10;
}

.flex__ac {
  align-items: center;
}

.footer__aux {
  display: flex;
  justify-content: space-between;
}

.footer__submenu {
  display: flex;
  flex-direction: row;
  justify-content: center;


  @include breakpoint(medium) {
    justify-content: flex-end;
  }


}

.footer--social {
  display: flex;
  align-items: center;
}

.footer--social__icon {
  width: 34px;
  transition: all .3s ease-in-out;

  &:hover, &:focus {
    transform: scale(1.15);

  }

}

.footer__menu {
  margin: 40px 0 20px 0;
  padding: 0;
  list-style: none;

  @include breakpoint(medium) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

}

.footer__item {
  padding: 10px 0;
}

.footer__alignment {
  text-align: center;
}

.footer__diamond {
  height: 32px;
  width: 32px;
  border: 2px solid #C7A57A;
  transform: rotate(45deg);
  transition: all .3s ease-in-out;

  &:hover {
    transform: rotate(45deg) scale(1.25);
  }

  &--margin {
    margin-right: 40px;
  }

  & img {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
  }


}

.footer--swish {
  position: absolute;
  bottom: 0;
  width: 100%;

  @include breakpoint(large) {
    bottom: unset;
    top: 0;
  }


}

.footer--buttons {
  display: flex;
  flex-direction: row;
  position: relative;

}

.footer--break {
  width: 4px;
  height: 96px;
  background-color: palette(brand, secondary);

  @include breakpoint(medium) {
    height: 192px;
  }


  &__content {
    background-color: transparent;
    width: 30px;
  }

  &__social {
    height: 30px;
    width: 2px;
  }

}


.btn--footer__content {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    transform: scale(0.75, 0.75);

    @include breakpoint(medium) {
      transform: scale(1, 1);
    }

    &.flipped {
      transform: scale(-0.75, -0.75);

      @include breakpoint(medium) {
        transform: scale(-1, -1);
      }
    }

  }

}

.btn--footer__hide {
  display: none;

  @include breakpoint(small) {
    display: block;
  }
}

@include breakpoint(medium) {

  .footer__alignment {

    text-align: unset;

    &--right {
      text-align: right;
    }
    &--left {
      text-align: left;
    }
  }
}

@include breakpoint(large) {}

// ABSOLUTE POSITIONED ITEMS

.footer--decor {

  display: none;

  @include breakpoint(medium) {

    display: block;
    position: absolute;
    bottom: 0;
    z-index: -5;

    &--fern__one {

      right: 0;
      transform: translate(5%,10%) rotate(15deg);
    }

    &--fern__two {
      left: 0;
      transform: scale(-1, 1) translateY(30%);

    }

    &--petal__one {
      left: 0;
      z-index: 10;
      bottom: 5%;
      transform: rotate(-90deg);

    }

    &--petal__two {
      left: 5%;
      z-index: 5;
      bottom: 7%;
      transform: scale(1.1) rotate(45deg);
    }
  }
}
//=====================================
// #HELPERS
//=====================================
.bump {
    margin-bottom: 20px;
}

.bump--sm {
    margin-bottom: 40px;
}

.bump--md {
    margin-bottom: 60px;
}

.nudge {
    margin-right: 15px;
}

.nudge__lg {
    margin-right: 30px;
}

.kill--margin {
    margin-bottom: 0;
}

.spacer {
    margin-bottom: 8vh;
    &--large {
        margin-bottom: 8vh;
        @include breakpoint(small) {
            margin-bottom: 15vh;
        }
    }
    &-top {
        margin-top: 8vh;
        &--large {
            margin-top: 8vh;
            @include breakpoint(small) {
                margin-top: 15vh;
            }
        }
    }
}

.p-spacer {
    padding-bottom: 8vh;
    &--large {
        padding-bottom: 8vh;
        @include breakpoint(small) {
            padding-bottom: 15vh;
        }
    }
    &-top {
        padding-top: 8vh;
        &--large {
            padding-top: 8vh;
            @include breakpoint(small) {
                padding-top: 15vh;
            }
        }
    }
}

.article--padding {

    &__both {
        padding-right: 1em;
        padding-left: 1em;

        @include breakpoint(small) {
            padding-right: 4em;
            padding-left: 4em;
        }
    }

    &__left {
        padding-left: 1em;

        @include breakpoint(small) {
            padding-left: 4em;
        }
    }

    &__right {
        padding-right: 1em;

        @include breakpoint(small) {
            padding-right: 4em;
        }
    }

}

.article--spacing {

    &__top {
        margin-top: 4vh;

        @include breakpoint(small) {
            margin-top: 8vh;
        }
    }

    &__bottom {
        margin-bottom: 4vh;

        @include breakpoint(small) {
            margin-bottom: 8vh;
        }
    }
}



.bump__pricelist--sm {
    margin-bottom: 25px;
}

.bump__pricelist--lg {
    margin-bottom: 60px;
}


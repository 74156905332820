//==========================================================================
// Abstrakts (ITCSS & BEM Method) Style coding
//==========================================================================

//======================================== CONFIGS
$newsGutter: 55px;
@import '_config/colours';
@import '_config/typography';
//======================================== UTILS
@import '_utils/breakpoints';
@import '_utils/text';
@import '_utils/spacing';
@import '_utils/cover';
@import '_utils/background-cover';
@import '_utils/clearfix';
@import '_utils/hidden-visible';
@import '_utils/index';
@import '_utils/animations';
//======================================== BASE
@import 'base/reset';
@import 'base/html-body';
@import 'base/typography';
@import 'base/icon';
@import 'base/inputs';
@import 'base/button';
//======================================== LAYOUT
@import 'layout/grid'; // Grid
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/chunk';
@import 'layout/menu';
//======================================== MODULES
@import 'modules/form';
@import 'modules/hero';
@import 'modules/cookie';
@import 'modules/pagination';
@import 'modules/hamburger';
@import 'modules/sideline';
@import 'modules/portfolio';
@import 'modules/hero-element';
@import 'modules/news';
@import 'modules/tabs';
@import 'modules/about';
@import 'modules/diamonds';
//======================================== VENDOR
//*
//======================================== PRINT
@import '_utils/print';

// ==========================================================================
// #ICONS
// ==========================================================================
.icon {
  @include bg-cover();
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  > svg { display: block; }
  path, polygon { transition: fill .3s; }
}

// ==========================================================================
// #ICONS-COLORS
// ==========================================================================
.icon--white {
  path { fill: white; }
}

.icon--black {
  path, polygon { fill: black !important; }
}

// Colors
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      path, polygon, rect, circle {
        fill: palette($color, $shade);
      }
    }
  }
}

// ==========================================================================
// #ICONS-SIZE
// ==========================================================================

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

.icon--signature {
  &,
  & > svg {
    width: 165px;
    height: auto;
  }
}

.icon--header {
  &,
  & > svg {
    width: auto;
    height: 100%;

    @media only screen and (min-width: 400px) {
      height: 75px;
    }
  }
}

.icon--title {
  &,
  & > svg {
    width: 100%;
  }

  @include breakpoint(medium) {
    width: auto;
    height: 50px;
  }

  &--lg {
    @include breakpoint(small) {
      transform: scale(1.5);
    }
  }

}


.icon--logo {
  &,
  & > svg {
    width: 100px;
    height: auto;

    @include breakpoint(medium) {
      width: 165px;
    }
  }
}


// ==========================================================================
// #ICONS-SOCIAL
// ==========================================================================
.icon--facebook, .icon--twitter, .icon--instagram, .icon--mail {
  svg > * { fill: palette(black) }
}

a:hover {
  .icon--facebook svg > * { fill: #3b5998; }
  .icon--twitter svg > * { fill: #00b6f1; }
  .icon--instagram svg > * { fill: #c32aa3 }
  .icon--mail svg > * { fill: black; }
}
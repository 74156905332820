// ==========================================================================
// #HEADER
// ==========================================================================
#header {

  padding: 20px 0;

  @include breakpoint(small) {
    padding: 40px 0;
  }
}

#header .logo {
  position: relative;
  z-index: 10;
  display: inline-block;
}

#header--logo {
  display: block;
}

.header--swish {
  position: absolute;
  top: 10%;
  right: 0;
  z-index: 2;
}

nav {

  display: none;

  @include breakpoint(medium) {

    display: block;
    position: relative;
    top: 3rem;
    float: right;

    & li {
      margin-right: 32px;
    }
  }
}


.menu__mobile {
  display: none;
}

.menu--active {

  @include breakpoint-max(medium) {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff url('/dist/img/RS-Pattern.png') bottom repeat-x;
    background-size: 600px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    .nav--mobile {

      display: flex;
      flex-direction: column;
      text-align: center;

      & a {
        margin-bottom: 50px;
        font-size: 32px;

        @include breakpoint-max(small) {
          margin-bottom: 25px;
          font-size: 26px;
        }

      }

    }

    .menu__mobile {
      display: block;
      margin-top: 50px;

      @include breakpoint-max(small) {
        margin-top: 25px;
      }
    }

    .mobile__social {
      display: flex;
      justify-content: center;
    }

    .mobile__contact {
      text-align: center;

      & p {
        font-size: 18px;
      }

    }




  }
}



//=====================================
// #SIDELINE
//=====================================
.sideline__hold {
  position: relative;
}

.sideline {
  width: 0;
  position: absolute;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 1px;
    height: 112px;
  }

  &--top {
    bottom: 110px;
    left: -50px;

    &::before {
      top: 30px;
      background-color: palette(brand, secondary);
    }

    &-diamond {
      position: relative;
      left: -16px;
      border: 2px solid palette(brand, secondary);
      background: #F6F6F8;
      width: 32px;
      height: 32px;
      transform: rotate(45deg);
      transition: all .3s ease-in-out;

      &:hover {
        transform: rotate(45deg) scale(1.2);
      }


    }

    &-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 20px;
      height: 20px;
    }
  }

  &--footer {
    bottom: 110px;
    left: 50%;

    &::before {
      top: 30px;
      background-color: palette(brand, secondary);
    }

    &-diamond {
      position: relative;
      left: -16px;
      border: 2px solid palette(brand, secondary);
      background: white;
      width: 32px;
      height: 32px;
      transform: rotate(45deg);
    }

  }

  &--full {
    left: -63px;
    top: -250px;

    &::before {
      height: 1000vh;
      background: palette(brand,secondary);
    }
  }


  &--btm {
    bottom: gutter(2);
    left: -63px;

    &::before {

      height: 1000vh;
      bottom: 15px;
      background-color: white;
    }

    &-diamond {
      position: relative;
      left: -7px;
      border: 1px solid palette(brand, reverse);
      background: white;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
    }
  }
}

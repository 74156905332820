.form-hold {

  &--negative {
    margin-top: -15%;
  }

  &--completed {
    .form--fern {
      transform: translate(0%, -50%) scale(0);
    }
    .form--petal {
      transform: scale(0.001);
    }
    .form--plant {
      transform: translate(0%, 0%) scale(0);
    }
    .form--transition {
      transform: translateY(125%);
    }
    .form--container {
      background-color: transparent;
      box-shadow: 0 0 0 0 rgba(191,191,191,0.5);
    }
    .form--success {
      opacity: 1;
      visibility: visible;
    }
  }
}

.form--container {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(191,191,191,0.5);
  padding: 25px;
  overflow: hidden;
  transition: box-shadow .2s, background-color .2s;
  transition-delay: 1s;
  position: relative;
  z-index: 5;

  @include breakpoint(small) {
    padding: 75px;

  }

}

.form--plant, .form--plant, .form--petal, .form--fern {
  display: none;
}


@include breakpoint(small) {

  .form--plant, .form--plant, .form--petal, .form--fern {
    display: block;
  }

  .form--plant {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%,-15%) scale(0.9);

    transition: all .6s ease-in-out;
  }

  .form--fern {
    position: absolute;
    top: 0;
    left: 0;

    transform: translate(-40%, 25%);
    transition: all .6s ease-in-out;
  }

  .form--petal {
    position: absolute;
    transform: scale(1);
    transition: all .8s ease-in-out;
    transition-delay: .2s;


    &__one {
      top: 50%;
      left: 0;
      transform: translate(-50%,-150%) rotate(140deg);
      z-index: 10;

    }

    &__two {
      bottom: 25%;
      right: 0;
      transform: translate(50%, 50%) scale(0.95);
      z-index: 10;
    }

    &__three {
      bottom: 0;
      right: 0;
      transform: translate(75%, -75%) scale(1.2) rotate(140deg);
    }
  }

}

.form--success {
  position: absolute;
  //top:50%;
  //transform: translateY(-50%);
  transition: all .5s ease-in-out;
  transition-delay: 1.5s;
  opacity: 0;
  visibility: hidden;
}

.form--transition {
  transition: all .6s ease-in-out;
  transition-delay: .5s;
}

.booking--hold {
  position: relative;
  z-index: 2;
}


.form--infoblock {
  display: flex;
  align-items: flex-end;

}

.infoblock--text {
  font-size: 2.6rem;
  line-height: 1.5;

  &__bump {
    margin-bottom: 5px;
  }

}

.infoblock--left {

  text-align: center;

  @include breakpoint(medium) {
    text-align: left;
  }
}

.infoblock--right {
  text-align: center;

  @include breakpoint(medium) {
    text-align: right;
  }

}

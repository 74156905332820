// ==========================================================================
// #CHUNK
// ==========================================================================
.chunk {
  overflow: hidden;
}


.chunk--background {

  position: relative;
  z-index: -10;
}


.chunk--white {
  background-color: white;
}

.chunk--image {
  background: white;


  @include breakpoint(medium) {
    background: white url('/dist/img/background-swish--middle.svg') repeat-x center;
  }

}


.chunk--split {
  background-color: white;
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    background-color: #F6F6F8;
  }

  &-half::before {
    height: 50%;
  }

  &-quarter::before {
    height: 25%;
    background-color: #AABCD5;

    }

}



.chunk--blue {
  background-color: #BFCBDC;
  position: relative;
  z-index: -4;

  &--half {
    background: linear-gradient(180deg, transparent 50%, #BFCBDC 50%);
  }

  &--image {
    background-image: url('/dist/img/watercolour-swish-2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

  }

  &__overlay {
    background-image: url(/dist/img/watercolour-swish-2.svg);
    background-size: 250%;
    background-position: bottom;
  }

}


.background__graphic {
  background-image: url('/dist/img/RS-Pattern.png');
  background-repeat: repeat-x;
  background-size: cover;
  background-position: bottom;

  @media only screen and (min-width: 475px) {
    background-size: contain;
  }
}
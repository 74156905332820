//=====================================
// #TABS
//=====================================
.tabs {

  &__head {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: relative;
    z-index: 10;
    padding: 0 5px;



    p {
      margin-bottom: 0;
    }


  }

  .head__item {


    display: block;
    font-size: 1.6rem;
    position: relative;
    padding-bottom: 15px;

    &::before {
      transition: all .3s;
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      height: 0;
      width: 100%;
      z-index: 10;
      background-color: #BFCBDC;
    }

    &:hover::before {

      height: 5px;
    }


    @include breakpoint(medium) {

      font-size: 2.2rem;

    }

    &--active {

      &::before, &:hover::before {

        height: 10px;
      }

    }
  }


  .content__item {
    display: none;

    &--active {
      display: block;
    }
  }
}


.pricelist--header {
  font-weight: bold;
  font-size: 2.6rem;
}

.pricelist--info p {
  font-size: 2.2rem;
}

.services__cta {
  font-size: 1.8rem;
}

.container__services {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 15px;
}

.services__heading {
  background: white;
  padding: 15px;
  text-align: center;
  position: relative;

}

.services__block {
  transition: all .2s ease-in-out;

  &:hover {
    transform: translateY(-2%);

    .services__helper {
      background: #333333;
      color: white;
    }


  }
}

.services__helper {
  background: palette(brand, tertiary);
  text-align: center;
  padding: 15px;
  margin: 0 15px;
  transition: all .3s ease-in-out;

  & p {
    margin: 0;
  }

}
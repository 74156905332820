.nav--icon {
  width: 40px;
  position: relative;
  padding: 3px;
  z-index: 10;

  @include breakpoint(small) {
    right: 0;
  }

}

.nav--icon:after,
.nav--icon:before,
.nav--icon div {
  background-color: #333333;
  border-radius: 3px;
  content: '';
  display: block;
  height: 2px;
  margin: 10px 0;
  transition: all .3s ease-in-out;
}


.nav-icon__open:before {
  transform: translateY(12px) rotate(135deg);
}

.nav-icon__open:after {
  transform: translateY(-12px) rotate(-135deg);
}

.nav-icon__open div {
  opacity: 0;
}

.nav__button {
  display: block;
  float: right;

  @include breakpoint(medium) {
    display: none;
  }

}
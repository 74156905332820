/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * {
    width: 100%;
  }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

/*
  Footer grid modification
*/
.g--footer {
}

.g__1-3 {
  lost-column: 1/3;
}

.g__ac {
  align-items: center;
}

.g__jc {
  justify-content: center;
}


.g--gutter__none {

  .g__1-2 {
    lost-column: 1/2 2 gutter(0);
  }

  .g__1-3 {
    lost-column: 1/3 3 gutter(0);
  }

  .g__1-4 {
    lost-column: 1/4 4 gutter(0);
  }
}


.g--gutter {
  .g__1-2 {
    lost-column: 1/2 2 gutter(1);
  }

  .g__1-3 {
    lost-column: 1/3 3 gutter(1);
  }

  .g__2-3 {
    lost-column: 2/3 3 gutter(1);
  }
}

.g--portfolio {
  .g__1-2 {
    lost-column: 1/2 2 gutter(1);
  }
}



.g--masonry {

  .g__1-3 {
    lost-column: 1/3 3 gutter(5);
  }

}

@include breakpoint(small) {
  .gs__1-2 {
    lost-column: 1/2;
  }

  .g--gutter {
    .gs__1-2 {
      lost-column: 1/2 2 gutter(5);
    }
  }

  .g--portfolio {
    .gs__1-2 {
      lost-column: 1/2 2 gutter(2);
    }
  }

}

@include breakpoint(medium) {

  .gm__ac {
    align-items: center;
  }

  .g--gutter {
    .gm__1-2 {
      lost-column: 1/2 2 gutter(1.5);
    }

  }

  .g--news {
    .gm__1-3 {
      lost-column: 1/3 row $newsGutter;
    }
  }

  .gm__1-2 {
    lost-column: 1/2;
  }

  .gm__1-3 {
    lost-column: 1/3;
  }

  .gm__1-4 {
    lost-column: 1/4;
  }


  .g--portfolio {
    .gm__1-4 {
      lost-column: 1/4 4 gutter(2);
    }
  }
}

@include breakpoint(large) {

  .g--gutter {
    .gl__1-3 {
      lost-column: 1/3 3 gutter(1.5);
    }
  }

  .gl__1-3 {
    lost-column: 1/3 0 0 flex;
  }
}

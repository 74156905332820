//=====================================
// #HERO-ELEMENT
//=====================================

.focal__hold, .polaroid__hold {
  position: relative;
}

.polaroid {
  margin: 0 auto;
  position: relative;
  background: white;
  width: 205px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.30);
  padding: 12px;


  img {
    width: 100%;
  }

  &--top {
    z-index: 2;
    transform: rotate(-15deg);
  }

  &--bottom {
    transform: rotate(10deg);
  }
}

.fern--background {
  position: absolute;
  z-index: -1;
  bottom: 0;
  max-width: 500px;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;

  img {
    width: 100%;
  }
}

.petal--rose {
  position: absolute;
  margin-top: -40px;

  img {
    height: 125px;
    width: 115px
  }
}


@include breakpoint(small) {

  .fern--background {
    max-width: none;
    width: 120%;
  }

  .polaroid__hold {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .polaroid {
    width: 220px;
  }
}


@include breakpoint(medium) {

  .polaroid {
    width: 240px;

    &--top {
      transform: rotate(-15deg) translate(-40%, 40%);

    }

    &--bottom {
      transform: rotate(10deg) translate(40%, 20%);
    }
  }
}

@include breakpoint(large) {
  .fern--background {
    width: 100%;
  }
}




//=====================================
// #NEWS
//=====================================
.type {
  padding: 5px;
  font-size: 1.8rem;
  background-color: palette(red, error);
  color: white;
}

.categories {
  text-align: center;

  li {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    font-weight: bold;
    font-size: 3.2rem;

    &.active {
      border-bottom: 5px solid #BFCBDC;
    }
  }
}

@include breakpoint(medium) {

}


.news__card {
  height: 570px;
  box-shadow: 0px 0px 20px 0px rgba(191, 191, 191, 0.5);
  border-radius: 5px;
  position: relative;
  background: #333333;
  margin-bottom: $newsGutter;
  overflow: hidden;


  &:nth-child(3n+2) {

    @include breakpoint(medium) {
      transform: translateY(-100px);
    }
  }

  &:nth-child(2) {

    @include breakpoint(medium) {
      height: 470px;
      transform: translateY(0);
    }
  }

  a {
    display: block;
    height: 100%;

    .news__hold {
      position: relative;
      z-index: 3;
      height: 100%;
    }


    &::before {
      content: "";
      @include cover();
      z-index: 2;
      background-color: black;
      opacity: .4;
      transition: all .3s ease-in-out;

    }

    &:hover {

      &::before {
        opacity: .8;
      }

      .news__info {
        transform: translateY(0);
      }

    }

  }
}


.news__info {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  transform: translateY(25%);
  transition: all .3s ease-in-out;

}

.img--background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


//=====================================
// #ARTICLES
//=====================================


.article__hero {
  align-items: center;
}

.article__category {
  font-size: 3rem;
  font-weight: bold;
}

.article__title {

  font-family: $big-heading-font;
  line-height: 1.2;
  font-size: 4.5rem;

  @include breakpoint(small) {
    font-size: 7.5rem;
  }
}

.article__intro {
  font-size: 2.6rem;
  font-weight: bold;
}

.article__text {
  line-height: 2;
  font-size: 1.8rem;

  p {
    line-height: 2;
  }
}

.article__author {
  text-align: center;

  @include breakpoint(small) {
    text-align: left;
  }
}


.article__thumbnail {
  max-width: 460px;
  max-height: 460px;
  float: right;
}



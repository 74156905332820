//=====================================
// #Diamonds
//=====================================

.decal {
  width: 0;
  position: absolute;

  &::before {
    content: "";
    position: absolute;
    left: -1px;
    width: 2px;
    height: 56px;

    @include breakpoint(medium) {
      height: 112px;
    }
  }

  &--top {
    top: 0;
    left: 50%;
    transform: translateY(-50%);

    &::before {
      top: 36px;
      background-color: palette(brand, secondary);
    }

    &-diamond {
      position: relative;
      left: -16px;
      border: 2px solid palette(brand, secondary);
      width: 32px;
      height: 32px;
      transform: rotate(45deg);
      transition: all .3s ease-in-out;
    }
  }
}

.decal__sm {
  width: 0;
  position: absolute;

  &::before {
    content: "";
    position: absolute;
    left: -1px;
    width: 2px;
    height: 38px;
  }

  &--btm {
    bottom: 0;
    left: 50%;
    transform: translateY(-50%);

    @include breakpoint(small) {
      bottom: 40%;
    }


    &::before {
      top: 0;
      background-color: palette(brand, secondary);
    }

    &-diamond {
      position: relative;
      top: 42px;
      left: -16px;
      border: 2px solid palette(brand, secondary);
      width: 32px;
      height: 32px;
      transform: rotate(45deg);
      transition: all .3s ease-in-out;
    }
  }
}

.decal__arrow {
  position: absolute;
  width: 15px;
  height: 2px;
  background: palette(brand, secondary);

  &--right {
    transform: rotate(90deg);
    top: 12px;
    right: 0px;
  }

  &--left {
    transform: rotate(-180deg);
    top: 19px;
    left: 6px;
  }

}


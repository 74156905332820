//=====================================
// #BUTTON
//=====================================
.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1.7;
  font-family: $heading-font;
  font-weight: 700;
  color: white;
  outline: none;
  background-color: black;
  padding: 8px 30px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;

  &--blue {

    width: 80%;
    border-radius: 0;
    background: palette(brand,tertiary);
    color: #333333;
    margin-top: -25%;
    z-index: 10;
    position: relative;

    @include breakpoint(small) {
      width: 350px;
    }
  }



  a {
    font-weight: bold;

    &:hover, &:focus {
      color: #fff;
    }
  }

  &:hover, &:focus {
    color: #fff;
    svg path { fill: white; }
    background: #333333;
  }
}

.btn--loadmore {
  border: 0;
  background: transparent;
  color: #333;
  font-family: $heading-font;
  font-weight: 700;
  font-size: 2.6rem;
  outline: none;
  cursor: pointer;

}



.btn--outline {
  border-radius: 0;
  background-color: transparent;
  border: solid 1px #333333;
  font-size: 3rem;
  color: #333333;
  width: 100%;

  @include breakpoint(small) {
    width: 275px;
  }
}

.footer--buttons {
  box-shadow: 0px 0px 20px 0px rgba(191,191,191,0.5);
}

.flipped {

  transform: scale(-0.75, -0.75);

  @include breakpoint(small) {
    transform: scale(-1,-1);
  }


}


.btn--footer {
  border-radius: 0;
  border: none;
  background: white;
  color: #333333;
  height: 96px;
  font-size: 4.5em;
  padding: 0;
  flex-grow: 1;

  //border-left: 2px solid palette(brand, secondary);
  //border-right: 2px solid palette(brand, secondary);


  @include breakpoint(small) {
    width: 100%;
    height: 192px;
  }
}


.btn--full { width: 100%; }

.btn--r { float: right; }

//=====================================
// #BUTTON-SIZES
//=====================================
.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}

//=====================================
// #BUTTON-COLORS
//=====================================
.btn--white {
  border-color: white;
  color: white;
}

.btn--white-fill {
  border-color: white;
  background-color: white;
  color: black;
}